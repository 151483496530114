import { useState } from "react"

import { CircularProgress, InputAdornment, TextField } from "@material-ui/core"
import { Search } from "@material-ui/icons"
import { useDebouncedCallback } from "use-debounce"

import { useTranslate } from "src/i18n/useTranslate"
import { DropdownButton } from "src/ui/Button/DropdownButton"
import { TextButton } from "src/ui/Button/TextButton"
import {
  BoxBottom,
  EmptyBox,
  ErrorBox,
  Item,
  ItemsList,
  Option,
  StyledSearch,
  StyledSelect,
} from "src/ui/DropdownSelect/sharedStyles"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { Maybe } from "src/utils/tsUtil"

export type TSelectOption<
  V extends string,
  O extends { [key: string]: unknown } = Record<string, unknown>,
> = O & {
  value: V
  label: string
  disabled?: boolean
}

export function DropdownSelect<
  V extends string,
  O extends { [key: string]: unknown } = Record<string, unknown>,
>({
  label,
  options = [],
  selectedValue,
  onChange,
  onSearch,
  onClearSelection,
  debounceDelay = 400,
  placeholder,
  loading,
  errorMsg,
}: {
  label: string
  options: TSelectOption<V, O>[]
  selectedValue: Maybe<TSelectOption<V>["value"]>
  onChange: ({ option }: { option: TSelectOption<V, O> }) => void
  onSearch?: (s: string) => void
  onClearSelection?: () => void
  debounceDelay?: number
  placeholder?: string
  loading?: boolean
  errorMsg?: React.ReactNode
}) {
  const { t, langKeys } = useTranslate()
  const [textfieldValue, setTextfieldValue] = useState("")

  const debouncedSearch = useDebouncedCallback(
    (search: string) => onSearch?.(search),
    debounceDelay,
    {
      leading: true,
    }
  )

  function handleChangeTextField(s: string) {
    debouncedSearch(s)
    setTextfieldValue(s)
  }

  function handleOptionClick(option: TSelectOption<V, O>) {
    onChange({ option })
  }

  return (
    <DropdownButton
      displayValue={label}
      placement="bottom-start"
      color="default"
    >
      {() => (
        <StyledSelect>
          {onSearch && (
            <StyledSearch>
              <TextField
                value={textfieldValue}
                onChange={(e) => handleChangeTextField(e.target.value)}
                fullWidth
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : (
                        <Search color="disabled" />
                      )}
                    </InputAdornment>
                  ),
                  placeholder,
                }}
              />
            </StyledSearch>
          )}

          {options.length === 0 && textfieldValue.length > 0 && (
            <EmptyBox>
              <MText variant="nano">
                {t(langKeys.search_no_matches_found)}
              </MText>
            </EmptyBox>
          )}

          <ItemsList
            $showBorderTop={!!onSearch}
            $showBorderBottom={!!onClearSelection}
          >
            {options.map((option) => (
              <Option
                key={option.label}
                onClick={() => handleOptionClick(option)}
                tabIndex={-1}
                $selected={option.value === selectedValue}
              >
                <Item>
                  <MText variant="nano">{option.label}</MText>
                </Item>
              </Option>
            ))}
          </ItemsList>

          {!!errorMsg && (
            <ErrorBox>
              <MBanner type="error" size="small">
                {errorMsg}
              </MBanner>
            </ErrorBox>
          )}

          <BoxBottom>
            <TextButton
              onClick={onClearSelection}
              disabled={!onClearSelection || !!!selectedValue}
            >
              {t(langKeys.clear_selection)}
            </TextButton>
          </BoxBottom>
        </StyledSelect>
      )}
    </DropdownButton>
  )
}
